import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { rem } from 'polished';
import Layout from '../components/layout';
import Hero from '../components/hero';
import SectionWithBg from '../components/section';
import { P, H2 } from '../components/typography';
import Container from '../components/container';
import { fluidImageType } from '../helper/prop-types';

const Section = styled(SectionWithBg)`
    padding-bottom: 0 !important;
`;

const PrivacyPage = ({ data }) => (
    <Layout
        title="Die Original Lechtaler zum Thema Datenschutz"
        description="Der Schutz und die Sicherheit deiner Daten sind uns sehr wichtig. Welche Informationen gespeichert werden und wie wir damit umgehen, siehst du hier."
    >
        <Hero image={data.file.childImageSharp.fluid} title="Datenschutz" />
        <Container maxWidth="medium">
            <Section evenOdd={false}>
                <P>
                    <H2>Datenschutzerklärung:</H2>
                </P>
                <P>
                    Wir möchten Sie so genau und verständlich wie möglich darüber informieren, wie
                    wir die besonderen Anforderungen des Datenschutzes bei uns umsetzen. Deshalb
                    haben wir Ihnen in folgender Datenschutzerklärung dargestellt in welcher Art,
                    welchem Umfang und zu welchem Zweck wir personenbezogene Daten erheben,
                    verarbeiten und nutzen. Ebenso die Weitergabe an Dritte wird hier beschrieben.
                </P>
            </Section>
            <Section evenOdd={false} padding={rem(15)}>
                <P>
                    <strong>
                        1. Name und Kontaktdaten des für die Verarbeitung Verantwortlichen sowie des
                        Datenschutzbeauftragten:
                    </strong>
                </P>
                <P>
                    <strong>Verantwortlich für diese Webseite ist:</strong>
                    <br />
                    Lutz!Markenvertrieb GmbH
                    <br />
                    Geschäftsführer: Herr Axel Knau
                    <br />
                    Sitz der Gesellschaft:
                    <br />
                    In der Mark 2<br />
                    33378 Rheda-Wiedenbrück <br />
                </P>
                <P>
                    <strong>Büroanschrift:</strong> <br />
                    Justus-von-Liebig-Straße 48
                    <br /> 86899 Landsberg am Lech
                    <br />
                    E-Mail:{' '}
                    <a href="mailto:info@die-original-lechtaler.de">
                        info@die-original-lechtaler.de
                    </a>
                </P>
                <P>
                    <strong>Der Datenschutzbeauftragte der verantwortlichen Stelle ist</strong>
                    <br />
                    RA Andreas Bode
                    <br />
                    Walderseestraße 14A <br /> 30177 Hannover <br /> T.: 0511 661023 <br />
                    <a href="mailto:Datenschutz@zurmuehlen-group.com">
                        Datenschutz@zurmuehlen-group.com
                    </a>
                </P>
            </Section>
            <Section evenOdd={false} padding={rem(15)}>
                <P>
                    <strong>2. Weitergabe von Daten</strong>
                </P>
                <P>
                    <strong>a) Beim Besuch der Website</strong>
                    <br /> Beim Aufrufen unserer Website werden durch den auf Ihrem Endgerät zum
                    Einsatz kommenden Browser automatisch Informationen an den Server unserer
                    Website gesendet. Diese Informationen werden temporär in einem sog. Logfile
                    gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis
                    zur automatisierten Löschung gespeichert:
                </P>
                <P>
                    • IP-Adresse des anfragenden Rechners, <br />• Datum und Uhrzeit des Zugriffs,{' '}
                    <br />• Name und URL der abgerufenen Datei, <br />• Website, von der aus der
                    Zugriff erfolgt (Referrer-URL), <br />• verwendeter Browser und ggf. das
                    Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers.
                </P>
                <P>Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:</P>
                <P>
                    • Gewährleistung eines reibungslosen Verbindungsaufbaus der Website, <br />•
                    Gewährleistung einer komfortablen Nutzung unserer Website, <br />• Auswertung
                    der Systemsicherheit und -stabilität sowie <br />• zu weiteren administrativen
                    Zwecken.
                </P>
                <P>
                    Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f
                    DSGVO. Unser berechtigtes Interesse folgt aus oben aufgelisteten Zwecken zur
                    Datenerhebung. In keinem Fall verwenden wir die erhobenen Daten zu dem Zweck,
                    Rückschlüsse auf Ihre Person zu ziehen. Darüber hinaus setzen wir beim Besuch
                    unserer Website Cookies ein. Nähere Erläuterungen dazu erhalten Sie unter den
                    Ziff. 4 dieser Datenschutzerklärung.
                </P>
                <P>
                    <strong>b) Bei Nutzung unseres Kontaktformulars</strong>
                    <br /> Bei Fragen jeglicher Art bieten wir Ihnen die Möglichkeit, mit uns über
                    ein auf der Website bereitgestelltes Formular Kontakt aufzunehmen. Dabei ist die
                    Angabe einer gültigen E-Mail- Adresse sowie des Vor- und Nachnamens
                    erforderlich, damit wir wissen, von wem die Anfrage stammt und um diese
                    beantworten zu können. Weitere Angaben können freiwillig getätigt werden. Die
                    Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns erfolgt nach 6 Abs. 1
                    S. 1 lit. f DSGVO auf Grund Ihrer Anfrage und dient der Kommunikation mit Ihnen.
                    Die für die Benutzung des Kontaktformulars von uns erhobenen personenbezogenen
                    Daten werden nach Erledigung der von Ihnen gestellten Anfrage automatisch
                    gelöscht.
                </P>
                <P>
                    <strong>c) Bei Anmeldung für den Newsletter</strong>
                    <br /> Für die Newsletter-Anmeldung wird eine E-Mail-Adresse benötigt. Sofern
                    Sie nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrücklich eingewilligt haben,
                    verwenden wir Ihre E-Mail-Adresse dafür, Ihnen regelmäßig unseren Newsletter
                    oder vergleichbare Informationen zu übersenden. Wir setzen das
                    „Double-opt-in“-Verfahren ein, um prüfen zu können, ob die Anmeldung vom Inhaber
                    der E-Mail-Adresse erfolgt ist. Die erhobenen Daten werden ausschließlich für
                    den Newsletterversand verwendet und nicht an Dritte weitergegeben. Die Abmeldung
                    ist jederzeit möglich, zum Beispiel über einen Link am Ende eines jeden
                    Newsletters. Ferner haben Sie die Möglichkeit, sich über die am Ende dieser
                    Datenschutzhinweise angegebene Kontaktmöglichkeit abzumelden.
                </P>
                <P>
                    <strong>d) Bei der Teilnahme am Gewinnspiel</strong>
                    <br /> Wenn Sie an einem Gewinnspiel von uns teilnehmen, erheben und verarbeiten
                    wir Ihre personenbezogenen Daten, soweit dies zur Begründung des
                    Rechtsverhältnisses mit Ihnen sowie zur anschließenden Durchführung und
                    Abwicklung des Gewinnspieles erforderlich ist. Folgenden personenbezogenen Daten
                    werden hierzu erhoben: Anrede, Vorname, Nachname, Geburtsdatum, E-Mail-Adresse
                    und Telefonnummer. Die Daten sind erforderlich zur Ermittlung, Benachrichtigung
                    des Gewinners, Kommunikation mit dem Gewinner sowie das Geburtsdatum zum Zweck
                    der Altersprüfung. Die Datenverarbeitung zum Zwecke der Durchführung des
                    Gewinnspiels mit uns erfolgt nach 6 Abs. 1 S. 1 lit. b DSGVO. Die für die
                    Teilnahme am Gewinnspiel von uns erhobenen personenbezogenen Daten werden nach
                    Beendigung des Gewinnspiels automatisch nach 14 Tagen gelöscht.
                </P>
            </Section>
            <Section evenOdd={false} padding={rem(15)}>
                <P>
                    <strong>3. Weitergabe von Daten</strong>
                </P>
                <P>
                    Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im
                    Folgenden aufgeführten Zwecken findet nicht statt. Wir geben Ihre persönlichen
                    Daten nur an Dritte weiter, wenn: <br /> • Sie Ihre nach Art. 6 Abs. 1 S. 1 lit.
                    a DSGVO ausdrückliche Einwilligung dazu erteilt haben,
                    <br /> • die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung,
                    Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund
                    zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der
                    Nichtweitergabe Ihrer Daten haben,
                    <br /> • für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c
                    DSGVO eine gesetzliche Verpflichtung besteht, sowie
                    <br /> • dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b <br />
                    DSGVO für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist.
                </P>
            </Section>
            <Section evenOdd={false} padding={rem(15)}>
                <P>
                    <strong>4. Google Analytics:</strong>
                </P>
                <P>
                    Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc.
                    („Google“). Zweck des Einsatzes ist die „bedarfsgerechte Gestaltung“ dieser
                    Internetseite. Die Webanalyse ermöglicht uns außerdem vor allem, Fehler der
                    Internetseite z.B. durch fehlerhafte Links zu erkennen und zu beheben. Google
                    Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer
                    gespeichert werden und die eine Analyse der Benutzung der Website durch Sie
                    ermöglichen. Dabei kommen sog. Client-IDs zur Anwendung, die dazu dienen,
                    pseudonyme Nutzungsprofile zu erstellen, die die Nutzung der Internetseiten
                    durch Desktop-Computer und mobile Endgeräte durch einen Nutzer gemeinsam
                    erfassen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung
                    dieser Website werden in der Regel an einen Server von Google in den USA
                    übertragen und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung
                    auf dieser Webseite, wird Ihre IP-Adresse von Google jedoch innerhalb von
                    Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des
                    Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in
                    Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA
                    übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird
                    Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um
                    Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der
                    Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem
                    Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem
                    Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google
                    zusammengeführt. Sie können die Speicherung der Cookies durch eine entsprechende
                    Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin,
                    dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website
                    vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der
                    durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten
                    (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch
                    Google verhindern, indem sie das unter dem folgenden Link (
                    <a
                        href="https://tools.google.com/dlpage/gaoptout?hl=de"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        http://tools.google.com/dlpage/gaoptout?hl=de
                    </a>
                    ) verfügbare Browser-Plugin herunterladen und installieren. Nähere Informationen
                    zu Nutzungsbedingungen und Datenschutz finden Sie unter{' '}
                    <a
                        href="https://www.google.com/analytics/terms/de.html"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        http://www.google.com/analytics/terms/de.html bzw.
                    </a>{' '}
                    <br />
                    unter{' '}
                    <a
                        href="http://www.google.com/intl/de/analytics/privacyoverview.html"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        http://www.google.com/intl/de/analytics/privacyoverview.html
                    </a>
                    <br />
                    Rechtsgrundlage für diese Datenverarbeitung ist Art. 6 Abs. 1 lit. f) DSGVO.
                    Unser &quot;Interesse&quot; i.S.d. Art. 6 Abs. 1 lit. f) sind der Betrieb und
                    die Fehlerbehebung, sowie die Optimierung und Anpassung dieser Internetseite an
                    die Bedürfnisse von Besuchern.
                </P>
            </Section>
            <Section evenOdd={false} padding={rem(15)}>
                <P>
                    <strong>5. Cookies</strong>
                </P>
                <P>
                    Wir setzen auf unserer Webseite Cookies ein. Hierbei handelt es sich um kleine
                    Dateien, die Ihr Browser automatisch erstellt und die auf Ihrem Endgerät
                    (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie unsere Seite
                    besuchen. <br /> Cookies richten auf Ihrem Endgerät keinen Schaden an, enthalten
                    keine Viren, Trojaner oder sonstige Schadsoftware. In dem Cookie werden
                    Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch
                    eingesetzten Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir dadurch
                    unmittelbar Kenntnis von Ihrer Identität erhalten. Der Einsatz von Cookies dient
                    einerseits dazu, die Nutzung unseres Angebots für Sie angenehmer zu gestalten.
                    Wir setzen sogenannte Session-Cookies ein, um zu erkennen, dass Sie einzelne
                    Seiten unserer Website bereits besucht haben. Diese werden nach Verlassen
                    unserer Seite automatisch gelöscht. <br /> Die durch Cookies verarbeiteten Daten
                    sind für die genannten Zwecke zur Wahrung unserer berechtigten Interessen sowie
                    der Dritter nach Art. 6 Abs. 1 S. 1 lit. f DSGVO erforderlich. Die meisten
                    Browser akzeptieren Cookies automatisch. Sie können Ihren Browser jedoch so
                    konfigurieren, dass keine Cookies auf Ihrem Computer gespeichert werden oder
                    stets ein Hinweis erscheint, bevor ein neuer Cookie angelegt wird. Die
                    vollständige Deaktivierung von Cookies kann jedoch dazu führen, dass Sie nicht
                    alle Funktionen unserer Website nutzen können.
                </P>
            </Section>
            <Section evenOdd={false} padding={rem(15)}>
                <P>
                    <strong>6. Social Plugins</strong>
                </P>
                <P>
                    Sie können auf unserer Webseite sog. „Social-Media-Buttons“ nutzen. Hierbei
                    setzen wir auf die „Shariff“-Lösung, was bedeutet, dass die Buttons auf der
                    Webseite lediglich als Grafik eingebunden sind. Der Button enthält lediglich
                    eine Verlinkung auf die entsprechende Webseite des jeweiligen Anbieters. Wenn
                    Sie einen Button anklicken, werden Sie zum jeweiligen Anbieter weitergeleitet.
                    Erst anschließend werden Ihre Daten an die jeweiligen Anbieter gesendet. Ein
                    Datenaustausch findet nicht statt, wenn Sie die Buttons nicht anklicken.
                    Informationen über die Erhebung und Verwendung Ihrer Daten in den sozialen
                    Netzwerken finden Sie in den jeweiligen Nutzungsbedingungen der entsprechenden
                    Anbieter. Auf unserer Website haben wir Social-Media-Buttons folgender
                    Unternehmen eingebunden: <br />• Facebook <br />• Instagram
                </P>
            </Section>
            <Section evenOdd={false} padding={rem(15)}>
                <P>
                    <strong>7. YouTube-Videos</strong>
                </P>
                <P>
                    Auf unserer Webseite betten wir Youtube-Videos ein. Betreiber der entsprechenden
                    Plugins ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA.
                    Besuchen Sie eine Seite mit dem YouTube-Plugin, wird eine Verbindung zu den
                    Servern von Youtube hergestellt. Youtube wird dabei bekannt gegeben, welche
                    Seiten Sie besuchen. Sind sie in Ihrem Youtube-Account eingeloggt, kann Youtube
                    Ihr Surfverhalten persönlich zuzuordnen. Dies verhindern Sie, indem Sie sich
                    vorher aus Ihrem Youtube-Account ausloggen. Youtube setzt Cookies ein, die
                    Hinweise über das Nutzerverhalten sammeln. Über das Google-Ad-Programm können
                    Sie Cookies deaktivieren, damit Sie beim Betrachten von Youtube-Videos mit
                    keinen Cookies rechnen müssen. Grundsätzlich können Sie jegliche Cookies
                    verhindern, indem Sie das Speichern von Cookies im Browser blockieren. Weitere
                    Informationen zum Datenschutz bei „Youtube“ erhalten Sie in der
                    Datenschutzerklärung des Anbieters:{' '}
                    <a
                        href="https://www.google.de/intl/de/policies/privacy/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        https://www.google.de/intl/de/policies/privacy/
                    </a>
                </P>
            </Section>
            <Section evenOdd={false} padding={rem(15)}>
                <P>
                    <strong>8. Betroffenenrechte</strong>
                </P>
                <P>
                    Sie haben das Recht:
                    <br /> • gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten
                    personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft über die
                    Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien
                    von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die
                    geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung,
                    Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines
                    Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben
                    wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung
                    einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren
                    Einzelheiten verlangen;
                    <br /> • gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder
                    Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu
                    verlangen;
                    <br /> • gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten
                    personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung
                    des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer
                    rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur
                    Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich
                    ist;
                    <br /> • gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer
                    personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen
                    bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung
                    ablehnen und wir die Daten nicht mehr benötigen, Sie jedoch diese zur
                    Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder
                    Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;
                    <br />• gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns
                    bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren
                    Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu
                    verlangen; • gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung
                    jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die
                    Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht
                    mehr fortführen dürfen und • gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde
                    zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde
                    Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes
                </P>
            </Section>
            <Section evenOdd={false} padding={rem(15)}>
                <P>
                    <strong>9. Widerspruchsrecht</strong>
                </P>
                <P>
                    Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen
                    gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden, haben Sie das Recht,
                    gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen
                    Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen
                    Situation ergeben oder sich der Widerspruch gegen Direktwerbung richtet. Im
                    letzteren Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer
                    besonderen Situation von uns umgesetzt wird. Möchten Sie von Ihrem Widerrufs-
                    oder Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an{' '}
                    <a href="mailto:info@die-original-lechtaler.de">
                        info@die-original-lechtaler.de
                    </a>
                    .
                </P>
            </Section>
            <Section evenOdd={false} padding={rem(15)}>
                <P>
                    <strong>10. Datensicherheit</strong>
                </P>
                <P>
                    Wir verwenden innerhalb des Website-Besuchs das verbreitete SSL-Verfahren
                    (Secure Socket Layer) in Verbindung mit der jeweils höchsten
                    Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird. In der Regel
                    handelt es sich dabei um eine 256 Bit Verschlüsselung. Falls Ihr Browser keine
                    256-Bit Verschlüsselung unterstützt, greifen wir stattdessen auf 128-Bit v3
                    Technologie zurück. Ob eine einzelne Seite unseres Internetauftrittes
                    verschlüsselt übertragen wird, erkennen Sie an der geschlossenen Darstellung des
                    Schüssel- beziehungsweise Schloss-Symbols in der unteren Statusleiste Ihres
                    Browsers.
                </P>
            </Section>
            <Section evenOdd={false} padding={rem(15)}>
                <P>
                    <strong>11. Aktualität und Änderung dieser Datenschutzerklärung</strong>
                </P>
                <P>
                    Diese Datenschutzerklärung ist aktuell gültig und hat den Stand September 2018.
                    Durch die Weiterentwicklung unserer Website und Angebote darüber oder aufgrund
                    geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig
                    werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle
                    Datenschutzerklärung kann jederzeit auf der Website unter{' '}
                    <a href="https://www.die-original-lechtaler.de/datenschutz/">
                        www.die-original-lechtaler.de/datenschutz/
                    </a>
                    . von Ihnen abgerufen und ausgedruckt werden.
                </P>
            </Section>
        </Container>
    </Layout>
);

PrivacyPage.propTypes = {
    data: PropTypes.shape({
        file: fluidImageType,
    }).isRequired,
};

export default PrivacyPage;

export const query = graphql`
    query {
        file(relativePath: { eq: "keyvisual/keyvisual-biergarden.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
    }
`;
